import { EOL } from 'os';
import 'setimmediate';
import winston, { format, transports } from 'winston';
import type Transport from 'winston-transport';
import { type LoggerConfig } from './LoggerConfig';

// Utility function for handling circular references in JSON, export as its used in tests

export function stringifyPossiblyCircular(value: any): string {
    try {
        return JSON.stringify(value);
    } catch (e) {
        return '[Circular]';
    }
}

// Custom format for pretty-printed console output
const prettyFormat = format.combine(
    format.colorize(),
    format.timestamp(),
    format.printf((info) => {
        const { level, message, timestamp, ...rest } = info;

        let suffix: string = '';
        if (Object.keys(rest).length > 0) {
            for (const [key, value] of Object.entries(rest)) {
                suffix += `${EOL}\t${key} - ${stringifyPossiblyCircular(value)}`;
            }
        }

        return `${timestamp} [${level}]: ${message}${suffix}`;
    })
);

function consoleTransport(logFormat: string): typeof transports.Console {
    return new transports.Console({
        format: logFormat === 'json' ? format.json() : prettyFormat,
    });
}

function dataDogTransport(params: transports.HttpTransportOptions): typeof transports.Http {
    const queryParams = new URLSearchParams({
        'dd-api-key': process.env.DD_RUM_TOKEN as string,
        ddsource: 'nodejs',
        service: 'sb-shell',
    });

    const datadogFormat = format.combine(
        format.timestamp(),
        format.errors({ stack: true }), // include error stack trace
        format.json()
    );

    return new transports.Http({
        host: 'http-intake.logs.datadoghq.com',
        ssl: true,
        path: `/api/v2/logs?${queryParams}`,
        format: datadogFormat,
        ...params,
    });
}

export function createWinstonLogger(loggerConfig: LoggerConfig): winston.Logger {
    const { logLevel: level, datadog, logFormat } = loggerConfig;

    const winstonTransports: Transport[] = [consoleTransport(logFormat)];

    if (datadog) {
        winstonTransports.push(dataDogTransport({ ...datadog, level }));
    }

    return winston.createLogger({
        level,
        transports: winstonTransports,
    });
}
